import { useRef, useEffect, useCallback } from 'react'
import { createPortal } from 'react-dom'

const portalRoot =
  typeof document !== `undefined` ? document.getElementById('portal') : null

function usePortal() {
  const portalElRef = useRef(
    typeof document !== `undefined` ? document.createElement('div') : null
  )

  useEffect(() => {
    const portalEffectRef = portalElRef.current
    portalRoot.appendChild(portalEffectRef)

    return () => {
      if (portalEffectRef) {
        portalRoot.removeChild(portalEffectRef)
      }
    }
  }, [portalElRef])

  const Portal = useCallback(
    ({ children }) =>
      portalElRef.current ? createPortal(children, portalElRef.current) : null,
    [portalElRef]
  )

  return Portal
}

export default usePortal
