import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import Slider from "components/Slider";
import headerImagesJSON from "./headerImages.mock.js";
import { useIntl } from "gatsby-plugin-intl";
import * as S from "./Header.styles";

const settings = {
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  pauseOnHover: false,
  responsive: [],
  dots: true,
  appendDots: (dots) => <S.ProgressBar>{dots}</S.ProgressBar>,
};

const Header = () => {
  const { formatMessage } = useIntl();
  const images = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "headerSlider" } }) {
        edges {
          node {
            id
            base
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  function compare(a, b) {
    if (a.node.base < b.node.base) {
      return -1;
    }
    if (a.node.base > b.node.base) {
      return 1;
    }
    return 0;
  }

  const orderedImages = images.allFile.edges.sort(compare);
  for (let i = 0; i < Object.values(headerImagesJSON).length; i++) {
    Object.values(headerImagesJSON)[i].cover = orderedImages[i];
  }
  return (
    <S.Wrapper>
      <S.SliderWrapper>
        <S.TextWrapper>
          <S.Title as="h1">
            <span>{formatMessage({ id: "heading.title" })}</span>
            <br />
            <span>{formatMessage({ id: "heading.complement" })}</span>
          </S.Title>
        </S.TextWrapper>
        <S.Slider>
          <Slider settings={{ ...settings }}>
            {Object.values(headerImagesJSON).map(({ id, alt, cover }) => {
              if (cover?.node?.childImageSharp?.fluid?.src) {
                return (
                  <Img
                    key={id}
                    fluid={cover?.node?.childImageSharp?.fluid || ""}
                    alt={alt}
                  />
                );
              }
            })}
          </Slider>
        </S.Slider>
        <S.SliderMobile>
          <Slider settings={{ ...settings }}>
            {Object.values(headerImagesJSON).map(({ id, alt, cover }) => {
              if (cover?.node?.childImageSharp?.fluid?.src) {
                return (
                  <Img
                    key={id}
                    fluid={cover?.node?.childImageSharp?.fluid || ""}
                    alt={alt}
                  />
                );
              }
            })}
          </Slider>
        </S.SliderMobile>
      </S.SliderWrapper>
    </S.Wrapper>
  );
};

export default Header;
