import React, { useState } from "react";
import CasesCards from "components/CasesCards";
import mock from "./Cases.mock";
import * as S from "./Cases.styles";
import { useIntl } from "gatsby-plugin-intl";

const Cases = () => {
  const [active, setActive] = useState(0);
  const { formatMessage } = useIntl();


  return (
    <S.Container id="cases">
      <S.Content>
        <S.Title>Cases</S.Title>
        <S.Nav>
          <S.NavUl>
            {[...Array(6)].map((_, index) => (
              <S.NavLink
                active={index === active}
                onClick={() => setActive(index)}
                key={index}
              >
                <p>{formatMessage({ id: `cases.title-${index + 1}` })}</p>
              </S.NavLink>
            ))}
          </S.NavUl>
        </S.Nav>
        <CasesCards data={mock[active] || []} active={active} />
      </S.Content>
    </S.Container>
  );
};

export default Cases;
