import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Slider from 'components/Slider'
import * as S from './CasesModal.styles'

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={`${className}_modal`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={`${className}_modal`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  )
}

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  className: 'center',
  centerPadding: '0px',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const Modal = ({ show, onClose, data }) => {
  const [slider, setSlider] = useState(null)
  useEffect(() => {
    if (slider) slider.slickGoTo(0)
  }, [slider])

  return (
    <S.Modal show={show}>
      <S.ContainerModal>
        <S.ModalContent>
          <S.ButtonClose onClick={onClose} />
          {show && (
            <Slider register={(slider) => setSlider(slider)} settings={settings}>
              {data?.map(({ description, image, video }) => (
                <S.Card key={description}>
                  <S.Image src={image} alt={description} />
                  <S.Description>{description}</S.Description>
                  {video ? (
                    <S.Iframe
                      width="1024"
                      height="576"
                      src={`https://www.youtube.com/embed/${video}`}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    />
                  ) : null}
                </S.Card>
              ))}
            </Slider>
          )}
        </S.ModalContent>
      </S.ContainerModal>
    </S.Modal>
  )
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
}

Modal.defaultProps = {
  show: false,
  data: [],
}

export default Modal
