import card1 from 'assets/images/cardsPngs/card1.png'
import card2 from 'assets/images/cardsPngs/card2.png'
import card3 from 'assets/images/cardsPngs/card3.png'
import card4 from 'assets/images/cardsPngs/card4.png'
import card5 from 'assets/images/cardsPngs/card5.png'
import card6 from 'assets/images/cardsPngs/card6.png'
import card7 from 'assets/images/cardsPngs/card7.png'
import card8 from 'assets/images/cardsPngs/card8.png'
import livestreaming1 from 'assets/images/livestreaming/livestreaming-1.png'
import livestreaming2 from 'assets/images/livestreaming/livestreaming-2.png'
import livestreaming3 from 'assets/images/livestreaming/livestreaming-3.jpg'
import livestreaming4 from 'assets/images/livestreaming/livestreaming-4.jpg'
import livestreaming5 from 'assets/images/livestreaming/livestreaming-5.png'
import livestreaming6 from 'assets/images/livestreaming/livestreaming-6.png'
import livestreaming7 from 'assets/images/livestreaming/livestreaming-7.png'

export default [
  {
    logo: card3,
    image: livestreaming3,
  },
  {
    logo: card2,
    image: livestreaming2,
  },
  {
    logo: card1,
    image: livestreaming1,
  },
  {
    logo: card4,
    image: livestreaming4,
  },
  {
    logo: card5,
    image: livestreaming5,
  },
  {
    logo: card6,
    image: livestreaming6,
  },
  {
    logo: card7,
    image: livestreaming7,
  },
]
