import React, { useState, useEffect } from "react";
import Animate, { FadeIn } from "animate-css-styled-components";
import { Title, Paragraph, SubTitle } from "components/Typography";
import { useIntl } from "gatsby-plugin-intl";
import useWindowDimensions from "hooks/useWindowDimensions";
import nextArrow from "assets/images/nextArrow.png";
import Logo from "assets/images/livestreaming/logo_tm1_live_streaming.png";
import DataCard from "../Live/CardData";
import * as S from "./Live.styles";

const Live = () => {
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);
  const [statePlay, setStatePlay] = useState("stopped");
  const [cardIndex, setCardIndex] = useState(0);
  const { formatMessage } = useIntl();

  const buttonNextAction = () => {
    if (cardIndex < 6) {
      setStatePlay("running");
      setCardIndex(cardIndex + 1);
      window.setTimeout(() => {
        setStatePlay("stopped");
      }, 1000);
    }
  };
  const buttonBackAction = () => {
    if (cardIndex > 0) {
      setStatePlay("running");
      setCardIndex(cardIndex - 1);
      window.setTimeout(() => {
        setStatePlay("stopped");
      }, 1000);
    }
  };
  useEffect(() => {
    if (width < 900) {
      setIsMobile(true);
    }
  }, []);

  const renderCard = () => {
    const firstItem = cardIndex === 0;
    const lastItem = cardIndex === 6;
    return (
      <S.Card>
        {statePlay === "running" ? (
          <Animate
            duration="1s"
            delay="0.1s"
            playState={statePlay}
            Animation={[FadeIn]}
          >
            <S.CircleImage src={DataCard[cardIndex].logo} />
          </Animate>
        ) : (
          <S.CircleImage src={DataCard[cardIndex].logo} />
        )}
        {statePlay === "running" ? (
          <Animate
            duration="1s"
            delay="0.1s"
            playState={statePlay}
            Animation={[FadeIn]}
          >
            <S.CardBody>
              <SubTitle>
                {formatMessage({ id: `live.card-${cardIndex + 1}.title` })}
              </SubTitle>
              <Paragraph>
                {formatMessage({ id: `live.card-${cardIndex + 1}.content` })}
              </Paragraph>
            </S.CardBody>
          </Animate>
        ) : (
          <S.FixedContent>
            <S.CardBody>
              <SubTitle>
                {formatMessage({ id: `live.card-${cardIndex + 1}.title` })}
              </SubTitle>
              <Paragraph>
                {formatMessage({ id: `live.card-${cardIndex + 1}.content` })}
              </Paragraph>
            </S.CardBody>
          </S.FixedContent>
        )}
        <S.ButtonsWrapper>
          <S.ButtonBack onClick={buttonBackAction} firstItem={firstItem}>
            <S.BackImg src={nextArrow} />
          </S.ButtonBack>
          <S.ButtonNext onClick={buttonNextAction} lastItem={lastItem}>
            <S.NextImg src={nextArrow} />
          </S.ButtonNext>
        </S.ButtonsWrapper>
      </S.Card>
    );
  };

  return (
    <S.Container id="liveStreaming">
      <S.Wrapper>
        <S.LeftSide isMobile={isMobile}>
          <S.TitleWapper>
            <img src={Logo} alt="logo TM1" />
          </S.TitleWapper>
          <S.ParagraphWapper>
            <Paragraph>{formatMessage({ id: "live.subtitle" })}</Paragraph>
          </S.ParagraphWapper>
          {renderCard()}
        </S.LeftSide>
        <S.RigthSide>
          {statePlay === "running" ? (
            <Animate
              duration="1.2s"
              delay="0.1s"
              playState={statePlay}
              Animation={[FadeIn]}
            >
              <S.RigthImage
                style={{ backgroundImage: `url(${DataCard[cardIndex].image})` }}
              />
            </Animate>
          ) : (
            <S.RigthImage
              style={{ backgroundImage: `url(${DataCard[cardIndex].image})` }}
            />
          )}
        </S.RigthSide>
      </S.Wrapper>
    </S.Container>
  );
};

export default Live;
