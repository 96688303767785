import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { SubTitle, Paragraph } from 'components/Typography'
import { useIntl } from 'gatsby-plugin-intl'
import * as S from './AboutUs.styles'
import Aos from 'aos'
import 'aos/dist/aos.css'

const ContentGroup = ({ title, description, alignSelf }) => (
  <S.ContentGroup alignSelf={alignSelf}>
    <S.ContentTitle>{title}</S.ContentTitle>
    <Paragraph>{description}</Paragraph>
  </S.ContentGroup>
)

export const AboutUs = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  const { formatMessage } = useIntl()

  return (
    <S.Wrapper id="aboutUs">
      <S.Line />
      <S.Content data-aos="fade-right">
        <S.TitleWrapper>
          <SubTitle>{formatMessage({ id: 'aboutUs.title' })}</SubTitle>
        </S.TitleWrapper>

        {[...Array(6)].map((_, index) => (
          <ContentGroup
            data-aos="fade-left"
            key={index}
            title={formatMessage({ id: `aboutUs.group.${index + 1}.title` })}
            description={formatMessage({
              id: `aboutUs.group.${index + 1}.content`,
            })}
            alignSelf={index >= 3 ? 'self-start' : 'center'}
          />
        ))}
      </S.Content>
    </S.Wrapper>
  )
}

ContentGroup.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  alignSelf: PropTypes.string.isRequired,
}

export default AboutUs
