import React from 'react'
import PropTypes from 'prop-types'
import SlickSlider from 'react-slick'
import ArrowIcon from 'assets/images/svgs/arrow.svg'
import * as S from './SliderClients.styles'

const defaultSettings = {
  arrows: true,
  prevArrow: (
    <S.ArrowRight>
      <ArrowIcon />
    </S.ArrowRight>
  ),
  nextArrow: (
    <S.ArrowLeft>
      <ArrowIcon />
    </S.ArrowLeft>
  ),
}

const Slider = ({ children, settings, asNavFor, sliderRef }) => (
  <S.Wrapper>
    <SlickSlider
      ref={sliderRef}
      asNavFor={asNavFor}
      {...defaultSettings}
      {...settings}
    >
      {children}
    </SlickSlider>
  </S.Wrapper>
)

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object.isRequired,
  sliderRef: PropTypes.object,
  asNavFor: PropTypes.object,
}

Slider.defaultProps = {
  sliderRef: null,
  asNavFor: null,
}

export default Slider
