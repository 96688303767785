import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.section`
  padding: 0 88px;

  .slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
    flex: 1 0 auto;

    div {
      outline: none;
    }
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-track,
  .slick-list {
    display: flex;
  }

  ${({ theme }) => css`
    .slick-track,
    .slick-list {
      display: flex;
    }

    .slick-slide > div {
      margin: 0 ${theme.spacings.xxsmall};
      flex: 1 0 auto;
      height: 100%;
    }

    .slick-list {
      margin: 0 -${theme.spacings.xxsmall};
    }

    ${media.greaterThan('large')`
      .slick-slide > div {
        margin: 0 ${theme.spacings.xxsmall};
      }

      .slick-list {
        margin: 0 -${theme.spacings.xxsmall};
      }
    `}

    .slick-prev,
    .slick-next {
      background-color: ${theme.colors.primary};
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      width: 64px;
      height: 64px;
      padding: 0;
      transform: translate(0, -50%);
      transition: all 0.3s;
      outline: none;
    }

    .slick-prev {
      left: calc(-${theme.spacings.xlarge} - 24px);
    }

    .slick-next {
      right: calc(-${theme.spacings.xlarge} - 24px);
    }

    .slick-prev.slick-disabled,
    .slick-next.slick-disabled {
      background-color: ${theme.colors.black};
      opacity: 0.2;
    }
  `}

  ${media.lessThan('medium')`
    padding: 0;
  `}
`

export const ArrowRight = styled.button`
  svg {
    height: 14px;
    width: auto;
  }
`

export const ArrowLeft = styled(ArrowRight)`
  svg {
    transform: rotate(180deg);
  }
`
