import React, { useState, useEffect } from "react";
import Animate, { FadeInRight } from "animate-css-styled-components";
import PropTypes from "prop-types";
import Slider from "../Slider";
import * as S from "./CasesCards.styles";
import rightArrow from "assets/images/right-arrow.png";
import Modal from "../CasesModal/";
import usePortal from "hooks/usePortal";
import { useIntl } from "gatsby-plugin-intl";

const CasesCards = ({ data, active }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [statePlay, setStatePlay] = useState("running");
  const [currentIndex, setCurrentIndex] = useState(0);
  const Portal = usePortal("portal");
  const { formatMessage } = useIntl();

  const runEffect = () => {
    setStatePlay("running");
    window.setTimeout(() => {
      setStatePlay("stopped");
    }, 3000);
  };

  const casesSettings = {
    dots: false,
    infinite: false,
    slidesToShow: Math.min(data.length, 5),
    slidesToScroll: 5,
    centerMode: false,
    centerPadding: "50px",
    swipeToSlide: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    runEffect();
  }, [active]);

  return (
    <>
      <S.WrapperCases center={data.length < 6}>
        <Slider settings={casesSettings}>
          {data?.map((item, key) =>
            statePlay === "running" ? (
              <Animate
                duration="3s"
                delay="0s"
                playState={statePlay}
                key={key}
                Animation={[FadeInRight]}
              >
                <S.Card key={key}>
                  <S.Wrapper
                    style={{
                      backgroundImage: `url(${item.backgroundCard})`,
                    }}
                  >
                    <S.LogoWrapper>
                      <S.Logo src={item.logo} />
                    </S.LogoWrapper>
                    <S.Readmore>
                      <S.Title>{item.title}</S.Title>
                      <S.Subtitle>{item.subtitle}</S.Subtitle>
                      <S.Link
                        onClick={() => {
                          setIsModalVisible(true);
                          setCurrentIndex(key);
                        }}
                      >
                        {formatMessage({ id: "cases.media" })}
                        <S.Arrow src={rightArrow} />
                      </S.Link>
                    </S.Readmore>
                  </S.Wrapper>
                </S.Card>
              </Animate>
            ) : (
              <S.Card key={key}>
                <S.Wrapper
                  style={{ backgroundImage: `url(${item.backgroundCard})` }}
                >
                  <S.LogoWrapper>
                    <S.Logo src={item.logo} />
                  </S.LogoWrapper>
                  <S.Readmore>
                    <S.Title>{item.title}</S.Title>
                    <S.Subtitle>{item.subtitle}</S.Subtitle>
                    <S.Link
                      onClick={() => {
                        setIsModalVisible(true);
                        setCurrentIndex(key);
                      }}
                    >
                      {formatMessage({ id: "cases.media" })}
                      <S.Arrow src={rightArrow} />
                    </S.Link>
                  </S.Readmore>
                </S.Wrapper>
              </S.Card>
            )
          )}
        </Slider>
      </S.WrapperCases>
      <Portal>
        <Modal
          show={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          data={data[currentIndex]?.data}
        />
      </Portal>
    </>
  );
};

CasesCards.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.string,
  onClick: PropTypes.string,
  active: PropTypes.number,
};

export default CasesCards;
