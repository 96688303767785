import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  pointer-events: all;
  ${({ theme, show }) => css`
    z-index: ${theme.layers.alwaysOnTop};
    opacity: ${show ? '1' : '0'};
    pointer-events: ${show ? 'all' : 'none'};
  `}

  .slick-slide {
    & > div {
      display: flex;
      align-items: center;
    }
  }
`

export const ContainerModal = styled.div`
  background-color: transparent;
  position: relative;
  width: 100%;
  height: 100%;
`

export const ButtonClose = styled.button`
  position: absolute;
  background-color: transparent;
  outline: none;
  width: 60px;
  height: 60px;
  border: none;
  z-index: 101;
  cursor: pointer;
  right: -70px;
  top: 0;

  &:before,
  &:after {
    content: ' ';
    position: absolute;
    width: 3px;
    height: 35px;
    background-color: #fff;
    bottom: 0;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  @media (max-width: 1367px) {
    right: -50px;
    top: 0;
  }

  @media (max-width: 1200px) {
    right: -60px;
    top: 10px;
  }
  
  @media (max-width: 910px) {
    right: -15px;
    top: 20px;
  }
  
  ${media.lessThan('medium')`
    right: 10px;
  `}
`

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin: 50px auto;
  max-width: 1100px;

  @media (max-width: 1400px) {
    max-width: 1100px;
  }

  ${media.lessThan('medium')`
    flex-direction: column;
  `}

  .slick-slide {
    div {
      max-height: 90%;

      @media (max-width: 1400px) {
        max-height: unset;
      }
    }
  }
`

export const Card = styled.div`
  width: 80vw !important;
  max-width: 1600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1400px) {
    height: 500px;
    max-height: unset;
  }

  ${media.lessThan('medium')`
    height: unset;
  `}
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 800px;

  @media (max-width: 1400px) {
    height: 100%;
    width: unset;
    padding-bottom: 50px;
  }
  
  ${media.lessThan('medium')`
    height: unset;
    max-height: 250px;
  `}
`

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.regular};
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    @media (max-width: 1400px) {
      margin-top: unset;
      position: relative;
      bottom: 30px;
    }
  `}
`

export const Iframe = styled.iframe`
  margin: 0 20px;
  border: none;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 1400px) {
    width: 700px;
    height: 394px;
    max-height: unset;
    left: 50%;
    transform: translateX(-50%);
  }

  ${media.lessThan('medium')`
    width: 100%;
    height: 300px;
    margin: 0;
  `}
`

export const ArrowRight = styled.button`
  background-color: purple;
`
