import styled, { css, keyframes } from "styled-components";
import media from "styled-media-query";

export const WrapperCases = styled.div`
  padding: 0;
  margin: 40px 0 100px;
  max-width: 1600px;

  ${({ center }) =>
    center &&
    css`
      ${media.greaterThan("medium")`
        .slick-track {
          justify-content: center;
        }

        .slick-slide {
          max-width: 275px;
        }
      `}
    `}

  .slick-arrow::after,.slick-arrow::before {
    background: #2ee577;
  }

  ${media.lessThan("medium")`
    margin: 32px 0 80px;

    .slick-arrow {
      bottom: -40px;

      &.slick-prev {
        left: 70% !important;
      }

      &.slick-next {
        right: 5% !important;
      }
    }
  `}
`;

export const Card = styled.div`
  max-width: 275px;
  height: 334px;
  position: relative;
  outline: none;
  overflow: hidden;
`;

export const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  max-width: 170px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
  background-size: cover;
  margin: 10px;

  ${media.lessThan("medium")`
    margin: 0;
  `}
`;

export const Readmore = styled.div`
  ${({ theme }) => css`
    border-top: 3px solid ${theme.colors.primary.main};
    background-color: black;
    width: 100%;
    height: 65%;
    padding: 15px;
    position: absolute;
    overflow: hidden;
    opacity: 0;
    transition: opacity 350ms;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${Card}:hover & {
      opacity: 1;
    }
  `}
`;

export const Logo = styled.img`
  max-width: 100%;
  max-height: 140px;
  transition: transform 350ms;

  ${Card}:hover & {
    transform: translateY(-110px);
  }
`;

export const Title = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    color: white;
    font-weight: ${theme.font.bold};
  `}
`;

export const Subtitle = styled.p`
  color: white;
`;

export const Link = styled.button`
  ${({ theme }) => css`
    position: absolute;
    color: ${theme.colors.primary.main};
    display: flex;
    align-items: center;
    bottom: 20px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 250ms;
    outline: none;

    &:hover {
      opacity: 0.7;
    }
  `}
`;

export const Arrow = styled.img`
  width: 10px;
  margin-left: 10px !important;
  position: relative;
  top: 2px;
`;
