import styled, { css } from "styled-components";
import media from "styled-media-query";
import Title from "components/Title";

export const Wrapper = styled.section`
  ${({ theme }) => css`
    background-image: linear-gradient(
      to bottom,
      ${theme.colors.white} 50%,
      ${theme.colors.secondaryDark} 50%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    ${Title} {
      font-weight: ${theme.font.bold};
      font-size: 32px;

      ${media.lessThan("medium")`
        font-size: ${theme.font.sizes.xlarge};
      `}
    }

    ${media.lessThan("huge")`
      overflow-x: hidden;
    `}

    ${media.lessThan("large")`
      margin-bottom: 200px;
    `}

    ${media.lessThan("medium")`
      padding: 80px 0 ${theme.spacings.small};
      margin-bottom: 0;

      ${Title} {
        margin-bottom: 0 !important;
      }
    `};
  `}
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SlideWrapper = styled.div`
  padding: 0 20px;
  margin: 50px 0;
  max-width: 1300px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* max-width: 100%; */
  flex-flow: wrap;
  justify-content: center;
  @media (max-width: 992px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const SlideItem = styled.div`
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

export const CardImageWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
  `}

  img,
  picture {
    transition: all 0.3s linear !important;
    width: 100%;
    max-width: 150px;
    max-height: 150px;
  }
`;

export const CardImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  border: 1px solid #000000;

  img,
  picture {
    transition: all 0.3s linear !important;
    width: 100%;
  }
`;

export const Img = styled.img``;
