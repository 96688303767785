import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import * as S from "./Video.styles";

const Video = () => {
  const { formatMessage } = useIntl();

  return (
    <S.Wrapper>
      <S.Title>{formatMessage({ id: "video.title" })}</S.Title>
      <S.Video>
        <S.Iframe
          src={formatMessage({ id: "video.link" })}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></S.Iframe>
      </S.Video>
    </S.Wrapper>
  );
};

export default Video;
