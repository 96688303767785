import styled, { css } from "styled-components";
import media from "styled-media-query";
import { SubTitle } from "components/Typography";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    position: relative;
    height: auto;

    background-color: ${theme.colors.primary.main};
    padding-bottom: 30px;
  `}
`;

export const Line = styled.span`
  ${({ theme }) => css`
    height: 245px;
    border-left: 2px solid black;
    position: absolute;
    left: 32px;
    top: -65px;

    ${media.lessThan("1367px")`
      height: 165px;
    `}

    ${media.lessThan("large")`
      height: 85px;
    `}

    ${media.lessThan("medium")`
      height: 111px;
      left: ${theme.spacings.xsmall};
    `}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-rows: 200px auto;
    grid-template-columns: 336px repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: ${theme.spacings.small};
    align-items: center;
    justify-content: space-between;
    margin: 10px;
    max-width: ${theme.grid.container};
    padding-right: ${theme.spacings.medium};
    padding-left: ${theme.spacings.medium};

    ${media.greaterThan("1648px")`
      padding-left: 0;
      padding-right: 0;
    `}

    ${media.greaterThan("huge")`
      grid-template-columns: 336px repeat(3, minmax(240px, 310px));
      max-width: 1600px;
      margin: 0 auto;
    `}

    ${media.lessThan("1370px")`
      grid-template-rows: 240px auto;
      padding: ${theme.spacings.medium};
      margin: -60px 0 ${theme.spacings.medium} 0;
    `}

    ${media.lessThan("large")`
      grid-template-rows: auto;
      padding: ${theme.spacings.medium};
    `}

    ${media.lessThan("medium")`
      grid-template-columns: 1fr;
      padding-top: 40px;
    `}
  `}
`;

export const TitleWrapper = styled.div`
  grid-row: 1/-1;
  margin-top: 24px;
  max-width: 297px;

  ${SubTitle} {
    line-height: 1.2;
  }

  ${media.greaterThan("huge")`
      margin-top: 50px;
    `}

  ${media.lessThan("large")`
      grid-row: 1;
      grid-column: 1/-1;
      margin-top: 0;
      max-width: unset;
    `}

    ${media.lessThan("medium")`
      max-width: 290px;

      ${SubTitle} {
        line-height: 30px;
        letter-spacing: 1px;
        max-width: 308px;
       
      }
    `}

    @media (max-width: 768px) {
    width: 200%;
    min-width: 100%;
    h2 {
      min-width: 100%;
      font-size: 22px;
    }
  }
`;

export const ContentGroup = styled.div`
  ${({ alignSelf = "center" }) => css`
    align-self: ${alignSelf};

    ${media.greaterThan("large")`
      align-self: flex-start;
      margin-top: 80px;
    `}

    ${media.lessThan("1370px")`
      align-self: flex-start;
      margin-top: 20px;
    `}

    ${media.lessThan("1285px")`
      align-self: flex-start;
      margin-top: 0;
    `}

    @media (max-width: 768px) {
      h4 {
        font-size: 20px;
      }
    }
  `}
`;

export const ContentTitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.light.title};
    font-size: 20px;
    margin-bottom: ${theme.spacings.xxsmall};
    font-weight: ${theme.font.bold};
  `}
`;
