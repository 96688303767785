import styled, { css } from "styled-components";
import media from "styled-media-query";
import Title from "components/Title";

export const Container = styled.div`
  margin-top: 0;

  display: flex;
  justify-content: center;

  @media (max-width: 1170px) {
    margin-top: 50px;
  }

  @media (max-width: 1370px) {
    margin-top: 50px;
  }
  @media (min-width: 1370px) {
    margin-top: 0px;
  }
`;

export const Wrapper = styled.section`
  ${({ theme }) => css`
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1300px;
    width: 100%;

    ${Title} {
      margin-bottom: 80px;
    }

    ${media.lessThan("medium")`
      padding: 80px 0 ${theme.spacings.small};
      margin-top: 160px;

      ${Title} {
        margin-bottom: ${theme.spacings.large};
      }
    `}

    ${media.lessThan("medium")`
      padding-bottom: 0;
    `}
  `}
  ${media.lessThan("1370px")`
      height: 500px;
    `}
`;

export const LeftSide = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.primary.main};
    width: 60%;
    height: 705px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;

    ${media.lessThan("1170px")`
       margin-top: 300px;
    `}
    ${media.lessThan("768px")`
       margin-top: 0;
    `}

    ${media.lessThan("large")`
      padding: 40px 24px;
      height: 400px;
      margin-bottom: 400px;
      width: 100%;

    `}
  `}

  ${media.lessThan("1370px")`

      height: 500px;
    `}
`;

export const RigthSide = styled.div`
  margin-bottom: -46px;
  /* height: 750px; */
  width: 40%;

  ${media.lessThan("1366px")`
      height: 530px;
      margin-bottom: -30px;
    `}

  ${media.lessThan("large")`
  height: 500px;

    display: none;
  `}
`;

export const CircleImage = styled.img`
  margin-top: 20px;
  margin-bottom: 30px;
  width: 160px;
  height: 160px;

  ${media.lessThan("1370px")`
      margin-bottom: 0px;
    `}
`;

export const ParagraphWapper = styled.div`
  margin: 30px 40px 0 40px;
  text-align: center;
  max-width: 570px;

  ${media.lessThan("medium")`
    text-align: left;
    margin: 0;
  `}

  ${media.lessThan("1370px")`
      margin-top: 15px;
    `}
`;

export const TitleWapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-top: 50px;
  img {
    width: 50%;
    height: auto;
  }

  h2 {
    text-align: center;
    font-size: 42px;
  }

  ${media.lessThan("medium")`
    margin-top: 0;
    margin-bottom: 16px;

    h2 {
      margin-bottom: 0 !important;
      margin-left: -10px;
    }
  `}

  ${media.lessThan("1370px")`
      margin-top: 15px;
    `}
`;

export const CardBody = styled.div`
  margin: 15px 20px 0 20px;
  text-align: center;

  ${media.lessThan("large")`
      margin: 5px 20px 0 20px;
    `}
`;

export const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.white};
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.5);
    margin-top: 70px;
    width: 100%;
    max-width: 420px;
    min-height: 470px;
    display: flex;
    align-items: center;
    flex-direction: column;

    ${media.lessThan("mobile")`
      margin-top: 60px;
    `}

    ${media.lessThan("1370px")`
      margin-top: 27px;
      min-height: 420px;
    `}
  `}
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  bottom: -20px;
  right: -20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonNext = styled.div`
  ${({ theme }) => css`
    background-color: ${(props) =>
      props.lastItem
        ? theme.colors.quartenary.main
        : theme.colors.tertiary.main};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    width: 72px;
    cursor: pointer;
    &:hover {
      background-color: ${(props) =>
        props.lastItem
          ? theme.colors.quartenary.main
          : theme.colors.quartenary.hover};
    }

    ${media.lessThan("medium")`
      height: 54px;
      width: 54px;
    `}
  `}
`;

export const ButtonBack = styled.div`
  ${({ theme }) => css`
    background-color: ${(props) =>
      props.firstItem
        ? theme.colors.quartenary.main
        : theme.colors.tertiary.main};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    width: 72px;
    cursor: pointer;
    &:hover {
      background-color: ${(props) =>
        props.firstItem
          ? theme.colors.quartenary.main
          : theme.colors.quartenary.hover};
    }

    ${media.lessThan("medium")`
      height: 54px;
      width: 54px;
    `}
  `}
`;

export const RigthImage = styled.div`
  width: 100%;
  height: 750px;
  background-repeat: no-repeat;
  background-size: cover;

  ${media.lessThan("1370px")`
      height: 580px;
      margin-bottom: -30px;
  `}
`;

export const NextImg = styled.img`
  width: 11px;
  height: 20px;
`;

export const BackImg = styled.img`
  width: 11px;
  height: 20px;
  transform: rotate(180deg);
`;

export const FixedContent = styled.div`
  margin-top: 4px;
`;
