import rocheBg from "assets/images/cards/roche-bg.jpg";
import rocheLogo from "assets/images/cards/roche-logo.png";
import bradescoBg from "assets/images/cards/bradesco-bg.png";
import bradescoLogo from "assets/images/cards/bradesco-logo.png";
import maryKayBg from "assets/images/cards/mary-kay-bg.png";
import maryKayLogo from "assets/images/cards/mary-kay-logo.png";
import bayerBg2 from "assets/images/cards/bayer2-bg.png";
import curbixBg from "assets/images/cards/curbix-bg.png";
import whatsappBg from "assets/images/cards/whatsapp-bg.png";
import bayerLogo from "assets/images/cards/bayer-logo.png";
import netflixLogo from "assets/images/cards/netflix-logo.png";
import facebookLogo from "assets/images/cards/facebook-logo.png";
import netflixBg from "assets/images/cards/netflix-bg.png";
import netflixBg3 from "assets/images/cards/netflix3-bg.png";
import netflixBg4 from "assets/images/cards/netflix4-bg.png";
import xpLogo from "assets/images/cards/xp-logo.png";
import xpBg from "assets/images/cards/xp-bg.png";
import rappiLogo from "assets/images/cards/rappi-logo.png";
import rappiBg from "assets/images/cards/rappi-bg.png";
import chilliBeansLogo from "assets/images/cards/chilli_beans_Logo.png";
import chilliBeansBg from "assets/images/cards/chilli-beans-bg.png";
import cocacolaLogo from "assets/images/cards/coca-cola-logo.png";
import cocacolaBg from "assets/images/cards/coca-cola-bg.png";
import trackLogo from "assets/images/cards/track-logo.png";
import netflixTudum1 from "assets/images/modalSlider/netflix-tudum/netflix-tudum-1.jpg";
import netflixTudum2 from "assets/images/modalSlider/netflix-tudum/netflix-tudum-2.jpg";
import netflixTudum3 from "assets/images/modalSlider/netflix-tudum/netflix-tudum-3.jpg";
import netflixTudum4 from "assets/images/modalSlider/netflix-tudum/netflix-tudum-4.jpg";
import netflixTudum5 from "assets/images/modalSlider/netflix-tudum/netflix-tudum-5.jpg";
import netflixTudum6 from "assets/images/modalSlider/netflix-tudum/netflix-tudum-6.jpg";
import netflixTudunBackground from "assets/images/modalSlider/netflix-tudum/netflix-tudum-7.jpg";
import xxpeedxp1 from "assets/images/modalSlider/xxpeed-xp/xxpeed-xp-1.jpg";
import xxpeedxp2 from "assets/images/modalSlider/xxpeed-xp/xxpeed-xp-2.jpg";
import xxpeedxp3 from "assets/images/modalSlider/xxpeed-xp/xxpeed-xp-3.jpg";
import xxpeedxp4 from "assets/images/modalSlider/xxpeed-xp/xxpeed-xp-4.jpg";
import xxpeedxp5 from "assets/images/modalSlider/xxpeed-xp/xp_xpeed_background.jpg";
import maryKay1 from "assets/images/modalSlider/mary-kay/mary-kay-1.jpg";
import maryKay2 from "assets/images/modalSlider/mary-kay/mary-kay-2.jpg";
import maryKay3 from "assets/images/modalSlider/mary-kay/mary-kay-3.jpg";
import maryKay4 from "assets/images/modalSlider/mary-kay/mary-kay-4.jpg";
import maryKay5 from "assets/images/modalSlider/mary-kay/mary-kay-5.jpg";
import maryKay6 from "assets/images/modalSlider/mary-kay/mary-kay-6.jpg";
import maryKay7 from "assets/images/modalSlider/mary-kay/mary-kay-7.jpg";
import maryKayBackground from "assets/images/modalSlider/mary-kay/mary-kay-background.jpg";
import rochePressDay1 from "assets/images/modalSlider/roche-press-day/roche-press-day-1.jpg";
import rochePressDay2 from "assets/images/modalSlider/roche-press-day/roche-press-day-2.jpg";
import rochePressDay3 from "assets/images/modalSlider/roche-press-day/roche-press-day-3.jpg";
import rochePressDay4 from "assets/images/modalSlider/roche-press-day/roche-press-day-4.jpg";
import rochePressDay5 from "assets/images/modalSlider/roche-press-day/roche-press-day-5.jpg";
import netflixEstacao1 from "assets/images/modalSlider/netflix-estacao/netflix-estacao-1.jpg";
import netflixEstacao2 from "assets/images/modalSlider/netflix-estacao/netflix-estacao-2.jpg";
import netflixEstacao3 from "assets/images/modalSlider/netflix-estacao/netflix-estacao-3.jpg";
import netflixEstacao4 from "assets/images/modalSlider/netflix-estacao/netflix-estacao-4.jpg";
import rappi1 from "assets/images/modalSlider/rappi/rappi-1.jpg";
import rappi2 from "assets/images/modalSlider/rappi/rappi-2.jpg";
import rappi3 from "assets/images/modalSlider/rappi/rappi-3.jpg";
import rappi4 from "assets/images/modalSlider/rappi/rappi-4.jpg";
import rappi5 from "assets/images/modalSlider/rappi/rappi-5.jpg";
import netflixCCXP1 from "assets/images/modalSlider/netflix-ccxp/netflix-ccxp-1.jpg";
import netflixCCXP2 from "assets/images/modalSlider/netflix-ccxp/netflix-ccxp-2.jpg";
import netflixCCXP3 from "assets/images/modalSlider/netflix-ccxp/netflix-ccxp-3.jpg";
import netflixCCXP4 from "assets/images/modalSlider/netflix-ccxp/netflix-ccxp-4.jpg";
import netflixCCXP5 from "assets/images/modalSlider/netflix-ccxp/netflix-ccxp-5.jpg";
import netflixCCXP6 from "assets/images/modalSlider/netflix-ccxp/netflix-ccxp-6.jpg";
import netflixCCXP7 from "assets/images/modalSlider/netflix-ccxp/netflix-ccxp-7.jpg";
import chilliBeans1 from "assets/images/modalSlider/chilli-beans/chilli-beans-1.jpg";
import chilliBeans2 from "assets/images/modalSlider/chilli-beans/chilli-beans-2.jpg";
import chilliBeans3 from "assets/images/modalSlider/chilli-beans/chilli-beans-3.jpg";
import chilliBeans4 from "assets/images/modalSlider/chilli-beans/chilli-beans-4.jpg";
import chilliBeans5 from "assets/images/modalSlider/chilli-beans/chilli-beans-5.jpg";
import fanta1 from "assets/images/modalSlider/fanta/fanta-1.jpg";
import fanta2 from "assets/images/modalSlider/fanta/fanta-2.jpg";
import fanta3 from "assets/images/modalSlider/fanta/fanta-3.jpg";
import fanta4 from "assets/images/modalSlider/fanta/fanta-4.jpg";
import fanta5 from "assets/images/modalSlider/fanta/fanta-5.jpg";
import impulsoBayer1 from "assets/images/modalSlider/impulso-bayer/impulso-bayer-1.jpg";
import impulsoBayer2 from "assets/images/modalSlider/impulso-bayer/impulso-bayer-2.jpg";
import impulsoBayer3 from "assets/images/modalSlider/impulso-bayer/impulso-bayer-3.jpg";
import impulsoBayer4 from "assets/images/modalSlider/impulso-bayer/impulso-bayer-4.jpg";
import impulsoBayer5 from "assets/images/modalSlider/impulso-bayer/impulso-bayer-5.jpg";
import impulsoBayer6 from "assets/images/modalSlider/impulso-bayer/impulso-bayer-6.jpg";
import bradesco1 from "assets/images/modalSlider/bradesco/bradesco-1.jpg";
import bradesco2 from "assets/images/modalSlider/bradesco/bradesco-2.jpg";
import bradesco3 from "assets/images/modalSlider/bradesco/bradesco-3.jpg";
import bradesco4 from "assets/images/modalSlider/bradesco/bradesco-4.jpg";
import bradesco5 from "assets/images/modalSlider/bradesco/bradesco-5.jpg";
import track1 from "assets/images/modalSlider/track/track-1.jpg";
import track2 from "assets/images/modalSlider/track/track-2.jpg";
import track3 from "assets/images/modalSlider/track/track-3.jpg";
import track4 from "assets/images/modalSlider/track/track-4.jpg";
import track5 from "assets/images/modalSlider/track/track-5.jpg";
import trackBG from "assets/images/modalSlider/track/trackcoBG.jpg";
import curbix1 from "assets/images/modalSlider/curbix/Curbix-1.jpg";
import curbix2 from "assets/images/modalSlider/curbix/Curbix-2.jpg";
import curbix3 from "assets/images/modalSlider/curbix/Curbix-3.jpg";
import curbix4 from "assets/images/modalSlider/curbix/Curbix-4.jpg";
import curbix5 from "assets/images/modalSlider/curbix/Curbix-5.jpg";
import curbixBackground from "assets/images/modalSlider/curbix/Curbix-background.jpg";
import whatsapp1 from "assets/images/modalSlider/whatsapp/whatsapp-1.png";
import whatsapp2 from "assets/images/modalSlider/whatsapp/whatsapp-2.png";
import whatsappLogo from "assets/images/modalSlider/whatsapp/whatsappLOGO.png";
import microsoftLogo from "assets/images/clientsSlider/microsoft.png";
import microsoftLogoTransparente from "assets/images/clientsSlider/microsoftTransparente.png";
import tm1Logo from "assets/images/tm1_next.png";
import * as showRural from "assets/images/cards/showRural";
import * as encontroRegional from "assets/images/cards/encontroRegional";
import * as premiereStranger from "assets/images/cards/premiereStranger";
import * as xpertxp from "assets/images/cards/xpertXperience";
import * as metaSummit from "assets/images/cards/metaSummit";
import * as MCAPS from "assets/images/cards/MCAPS";
import * as creatorsWeek from "assets/images/cards/creatorsWeek";
import * as maisBrasilNaTela from "assets/images/cards/maisBrasilNaTela";
import * as LLF from "assets/images/cards/LLF";
import * as tm1next from "assets/images/cards/tm1next";
import * as CRIE from "assets/images/cards/CRIE";
import * as round6 from "assets/images/cards/round6";
import * as activision from "assets/images/cards/activision";
import * as dow from "assets/images/cards/dow";
import * as olx from "assets/images/cards/olx";
import * as creatorWeek22 from "assets/images/cards/creatorWeek22";

export default [
  [
    {
      backgroundCard: xxpeedxp5,
      logo: xpLogo,
      title: "XP Financial Risks Summit",
      subtitle: "",
      data: [
        {
          image: xxpeedxp1,
          description: "XP Financial Risks Summit",
        },
        {
          image: xxpeedxp2,
          description: "XP Financial Risks Summit",
        },
        {
          image: xxpeedxp3,
          description: "XP Financial Risks Summit",
        },
        {
          image: xxpeedxp4,
          description: "XP Financial Risks Summit",
        },
      ],
    },
    {
      backgroundCard: creatorWeek22.CreatorWeek22BG,
      logo: creatorWeek22.Logo,
      title: "Creator week 22",
      subtitle: "",
      data: [
        {
          image: creatorWeek22.Image1,
          description: "Creator week 22",
        },
        {
          image: creatorWeek22.Image2,
          description: "Creator week 22",
        },
        {
          image: creatorWeek22.Image3,
          description: "Creator week 22",
        },
        {
          image: creatorWeek22.Image4,
          description: "Creator week 22",
        },
        {
          video: "g_b-SqDxXkE",
        },
      ],
    },
    {
      backgroundCard: activision.ActivisionBG,
      logo: activision.Logo,
      title: "Call of Duty",
      subtitle: "",
      data: [
        {
          image: activision.Image1,
          description: "Call of Duty",
        },
        {
          image: activision.Image2,
          description: "Call of Duty",
        },
        {
          image: activision.Image3,
          description: "Call of Duty",
        },
        {
          image: activision.Image4,
          description: "Call of Duty",
        },
        {
          video: "1KfgLSeLw8A",
        },
      ],
    },
    {
      backgroundCard: dow.DowBG,
      logo: dow.Logo,
      title: "Dow latin america - comercial meeting",
      subtitle: "",
      data: [
        {
          image: dow.Image1,
          description: "Dow latin america - comercial meeting",
        },
        {
          image: dow.Image2,
          description: "Dow latin america - comercial meeting",
        },
        {
          image: dow.Image3,
          description: "Dow latin america - comercial meeting",
        },
        {
          image: dow.Image4,
          description: "Dow latin america - comercial meeting",
        },
        {
          video: "DI7Zj5MNDX0",
        },
      ],
    },
    {
      backgroundCard: olx.ConectaOLXBG,
      logo: olx.Logo,
      title: "Conecta imobi.",
      subtitle: "",
      data: [
        {
          image: olx.Image1,
          description: "Conecta imobi.",
        },
        {
          image: olx.Image2,
          description: "Conecta imobi.",
        },
        {
          image: olx.Image3,
          description: "Conecta imobi.",
        },
        {
          image: olx.Image4,
          description: "Conecta imobi.",
        },
        {
          video: "VOSAB0VYf9Y",
        },
      ],
    },
    {
      backgroundCard: round6.Image6,
      logo: netflixLogo,
      title: "Netflix - Round 6",
      subtitle: "",
      data: [
        {
          image: round6.Image1,
          description: "Netflix - Round 6",
        },
        {
          image: round6.Image2,
          description: "Netflix - Round 6",
        },
        {
          image: round6.Image3,
          description: "Netflix - Round 6",
        },
        {
          image: round6.Image4,
          description: "Netflix - Round 6",
        },
        {
          video: "At2s9K3EkWg",
        },
      ],
    },
    {
      backgroundCard: netflixTudunBackground,
      logo: netflixLogo,
      title: "Netflix - Tudum",
      subtitle: "",
      data: [
        {
          image: netflixTudum1,
          description: "Netflix Tudum",
        },
        {
          image: netflixTudum2,
          description: "Netflix Tudum",
        },
        {
          image: netflixTudum3,
          description: "Netflix Tudum",
        },
        {
          image: netflixTudum4,
          description: "Netflix Tudum",
        },
        {
          image: netflixTudum5,
          description: "Netflix Tudum",
        },
        {
          image: netflixTudum6,
          description: "Netflix Tudum",
        },
        {
          video: "4OT_HcJHsr0",
        },
      ],
    },
    {
      backgroundCard: curbixBackground,
      logo: bayerLogo,
      title: "Bayer - Curbix",
      subtitle: "",
      data: [
        {
          image: curbix1,
          description: "Bayer - Curbix",
        },
        {
          image: curbix2,
          description: "Bayer - Curbix",
        },
        {
          image: curbix3,
          description: "Bayer - Curbix",
        },
        {
          image: curbix4,
          description: "Bayer - Curbix",
        },
        {
          image: curbix5,
          description: "Bayer - Curbix",
        },
        {
          video: "x4TO_ds3weE",
        },
      ],
    },
    {
      backgroundCard: maryKayBackground,
      logo: maryKayLogo,
      title: "Mary Kay - Festival On-line",
      subtitle: "",
      data: [
        {
          image: maryKay1,
          description: "Mary Kay - Festival On-line",
        },
        {
          image: maryKay2,
          description: "Mary Kay - Festival On-line",
        },
        {
          image: maryKay3,
          description: "Mary Kay - Festival On-line",
        },
        {
          image: maryKay4,
          description: "Mary Kay - Festival On-line",
        },
        {
          image: maryKay5,
          description: "Mary Kay - Festival On-line",
        },
        {
          image: maryKay6,
          description: "Mary Kay - Festival On-line",
        },
        {
          image: maryKay7,
          description: "Mary Kay - Festival On-line",
        },
        {
          video: "ysUfBle4KWQ",
        },
      ],
    },
    {
      backgroundCard: rocheBg,
      logo: rocheLogo,
      title: "Roche - Press Day",
      subtitle: "",
      data: [
        {
          image: rochePressDay1,
          description: "Roche - Press Day",
        },
        {
          image: rochePressDay2,
          description: "Roche - Press Day",
        },
        {
          image: rochePressDay3,
          description: "Roche - Press Day",
        },
        {
          image: rochePressDay4,
          description: "Roche - Press Day",
        },
        {
          image: rochePressDay5,
          description: "Roche - Press Day",
        },
        {
          video: "sONJ0zJaDXA",
        },
      ],
    },
    {
      backgroundCard: netflixBg3,
      logo: netflixLogo,
      title: "Netflix - Estação N",
      subtitle: "",
      data: [
        {
          image: netflixEstacao1,
          description: "Netflix - Estação N",
        },
        {
          image: netflixEstacao2,
          description: "Netflix - Estação N",
        },
        {
          image: netflixEstacao3,
          description: "Netflix - Estação N",
        },
        {
          image: netflixEstacao4,
          description: "Netflix - Estação N",
        },
        {
          video: "oFb853xExT0",
        },
      ],
    },
    {
      backgroundCard: rappiBg,
      logo: rappiLogo,
      title: "Rappi - Gastro Experience",
      subtitle: "",
      data: [
        {
          image: rappi1,
          description: "Rappi - Gastro Experience",
        },
        {
          image: rappi2,
          description: "Rappi - Gastro Experience",
        },
        {
          image: rappi3,
          description: "Rappi - Gastro Experience",
        },
        {
          image: rappi4,
          description: "Rappi - Gastro Experience",
        },
        {
          image: rappi5,
          description: "Rappi - Gastro Experience",
        },
      ],
    },
    {
      backgroundCard: netflixBg4,
      logo: netflixLogo,
      title: "Netflix - CCXP",
      subtitle: "",
      data: [
        {
          image: netflixCCXP1,
          description: "Netflix - CCXP",
        },
        {
          image: netflixCCXP2,
          description: "Netflix - CCXP",
        },
        {
          image: netflixCCXP3,
          description: "Netflix - CCXP",
        },
        {
          image: netflixCCXP4,
          description: "Netflix - CCXP",
        },
        {
          image: netflixCCXP5,
          description: "Netflix - CCXP",
        },
        {
          image: netflixCCXP6,
          description: "Netflix - CCXP",
        },
        {
          image: netflixCCXP7,
          description: "Netflix - CCXP",
        },
        {
          video: "fMDjzj8puOc",
        },
      ],
    },
    {
      backgroundCard: chilliBeansBg,
      logo: chilliBeansLogo,
      title: "Chilli Beans - CCXP",
      subtitle: "",
      data: [
        {
          image: chilliBeans1,
          description: "Chilli Beans - CCXP",
        },
        {
          image: chilliBeans2,
          description: "Chilli Beans - CCXP",
        },
        {
          image: chilliBeans3,
          description: "Chilli Beans - CCXP",
        },
        {
          image: chilliBeans4,
          description: "Chilli Beans - CCXP",
        },
        {
          image: chilliBeans5,
          description: "Chilli Beans - CCXP",
        },
        {
          video: "c9XSnZdXZC4",
        },
      ],
    },
    {
      backgroundCard: cocacolaBg,
      logo: cocacolaLogo,
      title: "Fanta - CCXP",
      subtitle: "",
      data: [
        {
          image: fanta1,
          description: "Fanta - CCXP",
        },
        {
          image: fanta2,
          description: "Fanta - CCXP",
        },
        {
          image: fanta3,
          description: "Fanta - CCXP",
        },
        {
          image: fanta4,
          description: "Fanta - CCXP",
        },
        {
          image: fanta5,
          description: "Fanta - CCXP",
        },
        {
          video: "A-F4k4y_ZOA",
        },
      ],
    },
    {
      backgroundCard: bayerBg2,
      logo: bayerLogo,
      title: "Bayer - Impulso Bayer",
      subtitle: "",
      data: [
        {
          image: impulsoBayer1,
          description: "Bayer - Impulso Bayer",
        },
        {
          image: impulsoBayer2,
          description: "Bayer - Impulso Bayer",
        },
        {
          image: impulsoBayer3,
          description: "Bayer - Impulso Bayer",
        },
        {
          image: impulsoBayer4,
          description: "Bayer - Impulso Bayer",
        },
        {
          image: impulsoBayer5,
          description: "Bayer - Impulso Bayer",
        },
        {
          image: impulsoBayer6,
          description: "Bayer - Impulso Bayer",
        },
        {
          video: "dYJVvMSvU1w",
        },
      ],
    },
    {
      backgroundCard: bradescoBg,
      logo: bradescoLogo,
      title: "Bradesco - Wine & Music Experience",
      subtitle: "",
      data: [
        {
          image: bradesco1,
          description: "Bradesco - Wine & Music Experience",
        },
        {
          image: bradesco2,
          description: "Bradesco - Wine & Music Experience",
        },
        {
          image: bradesco3,
          description: "Bradesco - Wine & Music Experience",
        },
        {
          image: bradesco4,
          description: "Bradesco - Wine & Music Experience",
        },
        {
          image: bradesco5,
          description: "Bradesco - Wine & Music Experience",
        },
        {
          video: "EmbrodkwDG0",
        },
      ],
    },
    {
      backgroundCard: trackBG,
      logo: trackLogo,
      title: "Track.co - CX SUMMIT",
      subtitle: "",
      data: [
        {
          image: track1,
          description: "Track.co - CX SUMMIT",
        },
        {
          image: track2,
          description: "Track.co - CX SUMMIT",
        },
        {
          image: track3,
          description: "Track.co - CX SUMMIT",
        },
        {
          image: track4,
          description: "Track.co - CX SUMMIT",
        },
        {
          image: track5,
          description: "Track.co - CX SUMMIT",
        },
        {
          video: "1LRLofmLfus",
        },
      ],
    },
    {
      backgroundCard: whatsappBg,
      logo: whatsappLogo,
      title: "Whatsapp Pagamentos",
      subtitle: "",
      data: [
        {
          image: whatsapp1,
          description: "Whatsapp Pagamentos",
        },
        {
          image: whatsapp2,
          description: "Whatsapp Pagamentos",
        },
      ],
    },
    {
      backgroundCard: maisBrasilNaTela.Image6,
      logo: netflixLogo,
      title: "Mais Brasil na Tela (Netflix)",
      subtitle: "",
      data: [
        {
          image: maisBrasilNaTela.Image1,
          description: "Mais Brasil na Tela (Netflix)",
        },
        {
          image: maisBrasilNaTela.Image2,
          description: "Mais Brasil na Tela (Netflix)",
        },
        {
          image: maisBrasilNaTela.Image3,
          description: "Mais Brasil na Tela (Netflix)",
        },
        {
          image: maisBrasilNaTela.Image4,
          description: "Mais Brasil na Tela (Netflix)",
        },
        {
          image: maisBrasilNaTela.Image5,
          description: "Mais Brasil na Tela (Netflix)",
        },
        {
          video: "VbPuI5cWn6c",
        },
      ],
    },
    {
      backgroundCard: creatorsWeek.Image6,
      logo: creatorWeek22.Logo,
      title: "Creators Week (Meta)",
      subtitle: "",
      data: [
        {
          image: creatorsWeek.Image1,
          description: "Creators Week (Meta)",
        },
        {
          image: creatorsWeek.Image2,
          description: "Creators Week (Meta)",
        },
        {
          image: creatorsWeek.Image3,
          description: "Creators Week (Meta)",
        },
        {
          image: creatorsWeek.Image4,
          description: "Creators Week (Meta)",
        },
        {
          image: creatorsWeek.Image5,
          description: "Creators Week (Meta)",
        },
        {
          video: "4bhYgqF6xGI",
        },
      ],
    },
    {
      backgroundCard: tm1next.Image4,
      logo: tm1Logo,
      title: "tm1 Next",
      subtitle: "",
      data: [
        {
          image: tm1next.Image1,
          description: "tm1 Next",
        },
        {
          image: tm1next.Image2,
          description: "tm1 Next",
        },
        {
          image: tm1next.Image3,
          description: "tm1 Next",
        },
        {
          video: "JIsuxqqijis",
        },
      ],
    },
    {
      backgroundCard: LLF.Image1,
      logo: microsoftLogoTransparente,
      title: "Latam Leadership Forum",
      subtitle: "",
      data: [
        {
          image: LLF.Image1,
          description: "Latam Leadership Forum",
        },
        {
          image: LLF.Image2,
          description: "Latam Leadership Forum",
        },
        {
          image: LLF.Image3,
          description: "Latam Leadership Forum",
        },
        {
          image: LLF.Image4,
          description: "Latam Leadership Forum",
        },
        {
          image: LLF.Image5,
          description: "Latam Leadership Forum",
        },
        {
          video: "pdHLinUFW0I",
        },
      ],
    },
    {
      backgroundCard: xpertxp.xpExpertBG,
      logo: xpLogo,
      title: "Xpert Xperience (XP)",
      subtitle: "",
      data: [
        {
          image: xpertxp.Image1,
          description: "Xpert Xperience (XP)",
        },
        {
          image: xpertxp.Image2,
          description: "Xpert Xperience (XP)",
        },
        {
          image: xpertxp.Image3,
          description: "Xpert Xperience (XP)",
        },
        {
          image: xpertxp.Image4,
          description: "Xpert Xperience (XP)",
        },
      ],
    },
    {
      backgroundCard: premiereStranger.StrangerThinksBG,
      logo: netflixLogo,
      title: "Première Stranger (Netflix)",
      subtitle: "",
      data: [
        {
          image: premiereStranger.Image1,
          description: "Première Stranger (Netflix)",
        },
        {
          image: premiereStranger.Image2,
          description: "Première Stranger (Netflix)",
        },
        {
          image: premiereStranger.Image3,
          description: "Première Stranger (Netflix)",
        },
        {
          image: premiereStranger.Image4,
          description: "Première Stranger (Netflix)",
        },
        {
          video: "ZX3X1h6ieyc",
        },
      ],
    },
    {
      backgroundCard: encontroRegional.EncontroRegionalXpBG,
      logo: xpLogo,
      title: "Encontro Regional BH (XP)",
      subtitle: "",
      data: [
        {
          image: encontroRegional.Image1,
          description: "Encontro Regional BH (XP)",
        },
        {
          image: encontroRegional.Image2,
          description: "Encontro Regional BH (XP)",
        },
        {
          image: encontroRegional.Image3,
          description: "Encontro Regional BH (XP)",
        },
        {
          image: encontroRegional.Image4,
          description: "Encontro Regional BH (XP)",
        },
        {
          image: encontroRegional.Image5,
          description: "Encontro Regional BH (XP)",
        },
        {
          video: "SkhgPjDxAAo",
        },
      ],
    },
    {
      backgroundCard: showRural.BayerShowRural,
      logo: bayerLogo,
      title: "Show Rural Cascavel (Bayer)",
      subtitle: "",
      data: [
        {
          image: showRural.Image1,
          description: "Show Rural Cascavel (Bayer)",
        },
        {
          image: showRural.Image2,
          description: "Show Rural Cascavel (Bayer)",
        },
        {
          image: showRural.Image3,
          description: "Show Rural Cascavel (Bayer)",
        },
        {
          image: showRural.Image4,
          description: "Show Rural Cascavel (Bayer)",
        },
        {
          image: showRural.Image5,
          description: "Show Rural Cascavel (Bayer)",
        },
        {
          video: "9A6XD_7xOEI",
        },
      ],
    },
    {
      backgroundCard: CRIE.CrieBG,
      logo: CRIE.logo,
      title: "Crie o impossível",
      subtitle: "",
      data: [
        {
          image: CRIE.Image1,
          description: "Crie o impossível",
        },
        {
          image: CRIE.Image2,
          description: "Crie o impossível",
        },
        {
          image: CRIE.Image3,
          description: "Crie o impossível",
        },
        {
          image: CRIE.Image4,
          description: "Crie o impossível",
        },
        {
          image: CRIE.Image5,
          description: "Crie o impossível",
        },
        {
          video: "aZC0KW8QLhA",
        },
      ],
    },
    {
      backgroundCard: metaSummit.MetaSummitBG,
      logo: facebookLogo,
      title: "Meta Summit (Meta)",
      subtitle: "",
      data: [
        {
          image: metaSummit.Image1,
          description: "Meta Summit (Meta)",
        },
        {
          image: metaSummit.Image2,
          description: "Meta Summit (Meta)",
        },
        {
          image: metaSummit.Image3,
          description: "Meta Summit (Meta)",
        },
        {
          image: metaSummit.Image4,
          description: "Meta Summit (Meta)",
        },
        {
          image: metaSummit.Image5,
          description: "Meta Summit (Meta)",
        },
        {
          video: "fgwBr5hf470",
        },
      ],
    },
    {
      backgroundCard: MCAPS.MicrosoftMcaps,
      logo: microsoftLogoTransparente,
      title: "MCAPS start",
      subtitle: "",
      data: [
        {
          image: MCAPS.Image1,
          description: "MCAPS start",
        },
        {
          image: MCAPS.Image2,
          description: "MCAPS start",
        },
        {
          image: MCAPS.Image3,
          description: "MCAPS start",
        },
        {
          image: MCAPS.Image4,
          description: "MCAPS start",
        },
        {
          image: MCAPS.Image5,
          description: "MCAPS start",
        },
      ],
    },
  ],
  // Eventos online , tm1 live streaming , plataformas digitais
  [
    {
      backgroundCard: xxpeedxp5,
      logo: xpLogo,
      title: "XP Financial Risks Summit",
      subtitle: "",
      data: [
        {
          image: xxpeedxp1,
          description: "XP Financial Risks Summit",
        },
        {
          image: xxpeedxp2,
          description: "XP Financial Risks Summit",
        },
        {
          image: xxpeedxp3,
          description: "XP Financial Risks Summit",
        },
        {
          image: xxpeedxp4,
          description: "XP Financial Risks Summit",
        },
      ],
    },
    {
      backgroundCard: dow.DowBG,
      logo: dow.Logo,
      title: "Dow latin america - comercial meeting",
      subtitle: "",
      data: [
        {
          image: dow.Image1,
          description: "Dow latin america - comercial meeting",
        },
        {
          image: dow.Image2,
          description: "Dow latin america - comercial meeting",
        },
        {
          image: dow.Image3,
          description: "Dow latin america - comercial meeting",
        },
        {
          image: dow.Image4,
          description: "Dow latin america - comercial meeting",
        },
        {
          video: "1KfgLSeLw8A",
        },
      ],
    },
    {
      backgroundCard: maisBrasilNaTela.Image6,
      logo: netflixLogo,
      title: "Mais Brasil na Tela (Netflix)",
      subtitle: "",
      data: [
        {
          image: maisBrasilNaTela.Image1,
          description: "Mais Brasil na Tela (Netflix)",
        },
        {
          image: maisBrasilNaTela.Image2,
          description: "Mais Brasil na Tela (Netflix)",
        },
        {
          image: maisBrasilNaTela.Image3,
          description: "Mais Brasil na Tela (Netflix)",
        },
        {
          image: maisBrasilNaTela.Image4,
          description: "Mais Brasil na Tela (Netflix)",
        },
        {
          image: maisBrasilNaTela.Image5,
          description: "Mais Brasil na Tela (Netflix)",
        },
        {
          video: "VbPuI5cWn6c",
        },
      ],
    },
    {
      backgroundCard: creatorsWeek.Image6,
      logo: creatorWeek22.Logo,
      title: "Creators Week (Meta)",
      subtitle: "",
      data: [
        {
          image: creatorsWeek.Image1,
          description: "Creators Week (Meta)",
        },
        {
          image: creatorsWeek.Image2,
          description: "Creators Week (Meta)",
        },
        {
          image: creatorsWeek.Image3,
          description: "Creators Week (Meta)",
        },
        {
          image: creatorsWeek.Image4,
          description: "Creators Week (Meta)",
        },
        {
          image: creatorsWeek.Image5,
          description: "Creators Week (Meta)",
        },
        {
          video: "4bhYgqF6xGI",
        },
      ],
    },
    {
      backgroundCard: netflixTudunBackground,
      logo: netflixLogo,
      title: "Netflix - Tudum",
      subtitle: "",
      data: [
        {
          image: netflixTudum1,
          description: "Netflix Tudum",
        },
        {
          image: netflixTudum2,
          description: "Netflix Tudum",
        },
        {
          image: netflixTudum3,
          description: "Netflix Tudum",
        },
        {
          image: netflixTudum4,
          description: "Netflix Tudum",
        },
        {
          image: netflixTudum5,
          description: "Netflix Tudum",
        },
        {
          image: netflixTudum6,
          description: "Netflix Tudum",
        },
        {
          video: "4OT_HcJHsr0",
        },
      ],
    },
    {
      backgroundCard: curbixBackground,
      logo: bayerLogo,
      title: "Bayer - Curbix",
      subtitle: "",
      data: [
        {
          image: curbix1,
          description: "Bayer - Curbix",
        },
        {
          image: curbix2,
          description: "Bayer - Curbix",
        },
        {
          image: curbix3,
          description: "Bayer - Curbix",
        },
        {
          image: curbix4,
          description: "Bayer - Curbix",
        },
        {
          image: curbix5,
          description: "Bayer - Curbix",
        },
        {
          video: "x4TO_ds3weE",
        },
      ],
    },

    {
      backgroundCard: maryKayBackground,
      logo: maryKayLogo,
      title: "Mary Kay - Festival On-line",
      subtitle: "",
      data: [
        {
          image: maryKay1,
          description: "Mary Kay - Festival On-line",
        },
        {
          image: maryKay2,
          description: "Mary Kay - Festival On-line",
        },
        {
          image: maryKay3,
          description: "Mary Kay - Festival On-line",
        },
        {
          image: maryKay4,
          description: "Mary Kay - Festival On-line",
        },
        {
          image: maryKay5,
          description: "Mary Kay - Festival On-line",
        },
        {
          image: maryKay6,
          description: "Mary Kay - Festival On-line",
        },
        {
          image: maryKay7,
          description: "Mary Kay - Festival On-line",
        },
        {
          video: "ysUfBle4KWQ",
        },
      ],
    },
    {
      backgroundCard: tm1next.Image4,
      logo: tm1Logo,
      title: "tm1 Next",
      subtitle: "",
      data: [
        {
          image: tm1next.Image1,
          description: "tm1 Next",
        },
        {
          image: tm1next.Image2,
          description: "tm1 Next",
        },
        {
          image: tm1next.Image3,
          description: "tm1 Next",
        },
        {
          video: "JIsuxqqijis",
        },
      ],
    },
    {
      backgroundCard: rocheBg,
      logo: rocheLogo,
      title: "Roche - Press Day",
      subtitle: "",
      data: [
        {
          image: rochePressDay1,
          description: "Roche - Press Day",
        },
        {
          image: rochePressDay2,
          description: "Roche - Press Day",
        },
        {
          image: rochePressDay3,
          description: "Roche - Press Day",
        },
        {
          image: rochePressDay4,
          description: "Roche - Press Day",
        },
        {
          image: rochePressDay5,
          description: "Roche - Press Day",
        },
        {
          video: "sONJ0zJaDXA",
        },
      ],
    },

    {
      backgroundCard: netflixBg3,
      logo: netflixLogo,
      title: "Netflix - Estação N",
      subtitle: "",
      data: [
        {
          image: netflixEstacao1,
          description: "Netflix - Estação N",
        },
        {
          image: netflixEstacao2,
          description: "Netflix - Estação N",
        },
        {
          image: netflixEstacao3,
          description: "Netflix - Estação N",
        },
        {
          image: netflixEstacao4,
          description: "Netflix - Estação N",
        },
        {
          video: "oFb853xExT0",
        },
      ],
    },

    {
      backgroundCard: whatsappBg,
      logo: whatsappLogo,
      title: "Whatsapp Pagamentos",
      subtitle: "",
      data: [
        {
          image: whatsapp1,
          description: "Whatsapp Pagamentos",
        },
        {
          image: whatsapp2,
          description: "Whatsapp Pagamentos",
        },
      ],
    },
  ],
  // Ativações e estandes
  [
    {
      backgroundCard: rappiBg,
      logo: rappiLogo,
      title: "Rappi - Gastro Experience",
      subtitle: "",
      data: [
        {
          image: rappi1,
          description: "Rappi - Gastro Experience",
        },
        {
          image: rappi2,
          description: "Rappi - Gastro Experience",
        },
        {
          image: rappi3,
          description: "Rappi - Gastro Experience",
        },
        {
          image: rappi4,
          description: "Rappi - Gastro Experience",
        },
        {
          image: rappi5,
          description: "Rappi - Gastro Experience",
        },
      ],
    },

    {
      backgroundCard: round6.Image6,
      logo: netflixLogo,
      title: "Netflix - Round 6",
      subtitle: "",
      data: [
        {
          image: round6.Image1,
          description: "Netflix - Round 6",
        },
        {
          image: round6.Image2,
          description: "Netflix - Round 6",
        },
        {
          image: round6.Image3,
          description: "Netflix - Round 6",
        },
        {
          image: round6.Image4,
          description: "Netflix - Round 6",
        },
        {
          video: "At2s9K3EkWg",
        },
      ],
    },
    {
      backgroundCard: netflixBg4,
      logo: netflixLogo,
      title: "Netflix - CCXP",
      subtitle: "",
      data: [
        {
          image: netflixCCXP1,
          description: "Netflix - CCXP",
        },
        {
          image: netflixCCXP2,
          description: "Netflix - CCXP",
        },
        {
          image: netflixCCXP3,
          description: "Netflix - CCXP",
        },
        {
          image: netflixCCXP4,
          description: "Netflix - CCXP",
        },
        {
          image: netflixCCXP5,
          description: "Netflix - CCXP",
        },
        {
          image: netflixCCXP6,
          description: "Netflix - CCXP",
        },
        {
          image: netflixCCXP7,
          description: "Netflix - CCXP",
        },
        {
          video: "fMDjzj8puOc",
        },
      ],
    },
    {
      backgroundCard: chilliBeansBg,
      logo: chilliBeansLogo,
      title: "Chilli Beans - CCXP",
      subtitle: "",
      data: [
        {
          image: chilliBeans1,
          description: "Chilli Beans - CCXP",
        },
        {
          image: chilliBeans2,
          description: "Chilli Beans - CCXP",
        },
        {
          image: chilliBeans3,
          description: "Chilli Beans - CCXP",
        },
        {
          image: chilliBeans4,
          description: "Chilli Beans - CCXP",
        },
        {
          image: chilliBeans5,
          description: "Chilli Beans - CCXP",
        },
        {
          video: "c9XSnZdXZC4",
        },
      ],
    },
    {
      backgroundCard: cocacolaBg,
      logo: cocacolaLogo,
      title: "Fanta - CCXP",
      subtitle: "",
      data: [
        {
          image: fanta1,
          description: "Fanta - CCXP",
        },
        {
          image: fanta2,
          description: "Fanta - CCXP",
        },
        {
          image: fanta3,
          description: "Fanta - CCXP",
        },
        {
          image: fanta4,
          description: "Fanta - CCXP",
        },
        {
          image: fanta5,
          description: "Fanta - CCXP",
        },
        {
          video: "A-F4k4y_ZOA",
        },
      ],
    },
  ],
  //  Eventos presenciais
  [
    {
      backgroundCard: olx.ConectaOLXBG,
      logo: olx.Logo,
      title: "Conecta imobi.",
      subtitle: "",
      data: [
        {
          image: olx.Image1,
          description: "Conecta imobi.",
        },
        {
          image: olx.Image2,
          description: "Conecta imobi.",
        },
        {
          image: olx.Image3,
          description: "Conecta imobi.",
        },
        {
          image: olx.Image4,
          description: "Conecta imobi.",
        },
        {
          video: "VOSAB0VYf9Y",
        },
      ],
    },
    {
      backgroundCard: activision.ActivisionBG,
      logo: activision.Logo,
      title: "Call of Duty",
      subtitle: "",
      data: [
        {
          image: activision.Image1,
          description: "Call of Duty",
        },
        {
          image: activision.Image2,
          description: "Call of Duty",
        },
        {
          image: activision.Image3,
          description: "Call of Duty",
        },
        {
          image: activision.Image4,
          description: "Call of Duty",
        },
        {
          video: "1KfgLSeLw8A",
        },
      ],
    },
    {
      backgroundCard: LLF.Image1,
      logo: microsoftLogoTransparente,
      title: "Latam Leadership Forum",
      subtitle: "",
      data: [
        {
          image: LLF.Image1,
          description: "Latam Leadership Forum",
        },
        {
          image: LLF.Image2,
          description: "Latam Leadership Forum",
        },
        {
          image: LLF.Image3,
          description: "Latam Leadership Forum",
        },
        {
          image: LLF.Image4,
          description: "Latam Leadership Forum",
        },
        {
          image: LLF.Image5,
          description: "Latam Leadership Forum",
        },
        {
          video: "pdHLinUFW0I",
        },
      ],
    },
    {
      backgroundCard: xpertxp.xpExpertBG,
      logo: xpLogo,
      title: "Xpert Xperience (XP)",
      subtitle: "",
      data: [
        {
          image: xpertxp.Image1,
          description: "Xpert Xperience (XP)",
        },
        {
          image: xpertxp.Image2,
          description: "Xpert Xperience (XP)",
        },
        {
          image: xpertxp.Image3,
          description: "Xpert Xperience (XP)",
        },
        {
          image: xpertxp.Image4,
          description: "Xpert Xperience (XP)",
        },
      ],
    },
    {
      backgroundCard: premiereStranger.StrangerThinksBG,
      logo: netflixLogo,
      title: "Première Stranger (Netflix)",
      subtitle: "",
      data: [
        {
          image: premiereStranger.Image1,
          description: "Première Stranger (Netflix)",
        },
        {
          image: premiereStranger.Image2,
          description: "Première Stranger (Netflix)",
        },
        {
          image: premiereStranger.Image3,
          description: "Première Stranger (Netflix)",
        },
        {
          image: premiereStranger.Image4,
          description: "Première Stranger (Netflix)",
        },
        {
          video: "ZX3X1h6ieyc",
        },
      ],
    },
    {
      backgroundCard: encontroRegional.EncontroRegionalXpBG,
      logo: xpLogo,
      title: "Encontro Regional BH (XP)",
      subtitle: "",
      data: [
        {
          image: encontroRegional.Image1,
          description: "Encontro Regional BH (XP)",
        },
        {
          image: encontroRegional.Image2,
          description: "Encontro Regional BH (XP)",
        },
        {
          image: encontroRegional.Image3,
          description: "Encontro Regional BH (XP)",
        },
        {
          image: encontroRegional.Image4,
          description: "Encontro Regional BH (XP)",
        },
        {
          image: encontroRegional.Image5,
          description: "Encontro Regional BH (XP)",
        },
        {
          video: "SkhgPjDxAAo",
        },
      ],
    },
    {
      backgroundCard: showRural.BayerShowRural,
      logo: bayerLogo,
      title: "Show Rural Cascavel (Bayer)",
      subtitle: "",
      data: [
        {
          image: showRural.Image1,
          description: "Show Rural Cascavel (Bayer)",
        },
        {
          image: showRural.Image2,
          description: "Show Rural Cascavel (Bayer)",
        },
        {
          image: showRural.Image3,
          description: "Show Rural Cascavel (Bayer)",
        },
        {
          image: showRural.Image4,
          description: "Show Rural Cascavel (Bayer)",
        },
        {
          image: showRural.Image5,
          description: "Show Rural Cascavel (Bayer)",
        },
        {
          video: "9A6XD_7xOEI",
        },
      ],
    },
    {
      backgroundCard: bayerBg2,
      logo: bayerLogo,
      title: "Bayer - Impulso Bayer",
      subtitle: "",
      data: [
        {
          image: impulsoBayer1,
          description: "Bayer - Impulso Bayer",
        },
        {
          image: impulsoBayer2,
          description: "Bayer - Impulso Bayer",
        },
        {
          image: impulsoBayer3,
          description: "Bayer - Impulso Bayer",
        },
        {
          image: impulsoBayer4,
          description: "Bayer - Impulso Bayer",
        },
        {
          image: impulsoBayer5,
          description: "Bayer - Impulso Bayer",
        },
        {
          image: impulsoBayer6,
          description: "Bayer - Impulso Bayer",
        },
        {
          video: "dYJVvMSvU1w",
        },
      ],
    },

    {
      backgroundCard: bradescoBg,
      logo: bradescoLogo,
      title: "Bradesco - Wine & Music Experience",
      subtitle: "",
      data: [
        {
          image: bradesco1,
          description: "Bradesco - Wine & Music Experience",
        },
        {
          image: bradesco2,
          description: "Bradesco - Wine & Music Experience",
        },
        {
          image: bradesco3,
          description: "Bradesco - Wine & Music Experience",
        },
        {
          image: bradesco4,
          description: "Bradesco - Wine & Music Experience",
        },
        {
          image: bradesco5,
          description: "Bradesco - Wine & Music Experience",
        },
        {
          video: "EmbrodkwDG0",
        },
      ],
    },
    {
      backgroundCard: trackBG,
      logo: trackLogo,
      title: "Track.co - CX SUMMIT",
      subtitle: "",
      data: [
        {
          image: track1,
          description: "Track.co - CX SUMMIT",
        },
        {
          image: track2,
          description: "Track.co - CX SUMMIT",
        },
        {
          image: track3,
          description: "Track.co - CX SUMMIT",
        },
        {
          image: track4,
          description: "Track.co - CX SUMMIT",
        },
        {
          image: track5,
          description: "Track.co - CX SUMMIT",
        },
        {
          video: "1LRLofmLfus",
        },
      ],
    },
  ],
  //  Eventos Hibridos
  [
    {
      backgroundCard: metaSummit.MetaSummitBG,
      logo: facebookLogo,
      title: "Meta Summit (Meta)",
      subtitle: "",
      data: [
        {
          image: metaSummit.Image1,
          description: "Meta Summit (Meta)",
        },
        {
          image: metaSummit.Image2,
          description: "Meta Summit (Meta)",
        },
        {
          image: metaSummit.Image3,
          description: "Meta Summit (Meta)",
        },
        {
          image: metaSummit.Image4,
          description: "Meta Summit (Meta)",
        },
        {
          image: metaSummit.Image5,
          description: "Meta Summit (Meta)",
        },
        {
          video: "fgwBr5hf470",
        },
      ],
    },
    {
      backgroundCard: creatorWeek22.CreatorWeek22BG,
      logo: creatorWeek22.Logo,
      title: "Creator week 22",
      subtitle: "",
      data: [
        {
          image: creatorWeek22.Image1,
          description: "Creator week 22",
        },
        {
          image: creatorWeek22.Image2,
          description: "Creator week 22",
        },
        {
          image: creatorWeek22.Image3,
          description: "Creator week 22",
        },
        {
          image: creatorWeek22.Image4,
          description: "Creator week 22",
        },
        {
          video: "g_b-SqDxXkE",
        },
      ],
    },
    {
      backgroundCard: MCAPS.MicrosoftMcaps,
      logo: microsoftLogoTransparente,
      title: "MCAPS start",
      subtitle: "",
      data: [
        {
          image: MCAPS.Image1,
          description: "MCAPS start",
        },
        {
          image: MCAPS.Image2,
          description: "MCAPS start",
        },
        {
          image: MCAPS.Image3,
          description: "MCAPS start",
        },
        {
          image: MCAPS.Image4,
          description: "MCAPS start",
        },
        {
          image: MCAPS.Image5,
          description: "MCAPS start",
        },
        {
          video: "krF7ZZ5TyNI",
        },
      ],
    },
  ],
  // Açoes sociais
  [
    {
      backgroundCard: CRIE.CrieBG,
      logo: CRIE.logo,
      title: "Crie o impossível",
      subtitle: "",
      data: [
        {
          image: CRIE.Image1,
          description: "Crie o impossível",
        },
        {
          image: CRIE.Image2,
          description: "Crie o impossível",
        },
        {
          image: CRIE.Image3,
          description: "Crie o impossível",
        },
        {
          image: CRIE.Image4,
          description: "Crie o impossível",
        },
        {
          image: CRIE.Image5,
          description: "Crie o impossível",
        },
        {
          video: "aZC0KW8QLhA",
        },
      ],
    },
  ],
];
