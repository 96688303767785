import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: 100%;
    overflow: hidden;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 1300px;
    margin: 140px auto 10px auto;

    @media (max-width: 1366px) {
      max-width: 830px;
    }

    ${media.lessThan("medium")`
      margin: ${theme.spacings.medium} auto;
    `}
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: 32px;
    width: 100%;
    text-align: center;
    margin-bottom: ${theme.spacings.medium};
    font-weight: ${theme.font.bold};

    ${media.lessThan("medium")`
      font-size: ${theme.font.sizes.xlarge};
    `}
  `}
`;

export const Video = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;

  ${media.lessThan("medium")`
    object-fit: cover;
    height: 240px;
  `}
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: transparent;
`;
