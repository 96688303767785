import styled, { css } from 'styled-components'
import media from 'styled-media-query'

const titleModifiers = {
  secondary: (theme) => css`
    color: ${theme.colors.secondary};
  `,
  dark: (theme) => css`
    color: ${theme.colors.gray[100]};
  `,
}

const Title = styled.h2`
  ${({ theme, color = 'secondary' }) => css`
    font-size: ${theme.font.sizes.xxlarge};
    font-family: ${theme.font.family};

    &:not(:last-child) {
      margin-bottom: ${theme.spacings.xsmall};
    }

    ${media.lessThan('medium')`
      font-size: ${theme.font.sizes.xlarge};
      line-height: 48px;
    `}

    ${titleModifiers[color](theme)}
  `}
`

export default Title
